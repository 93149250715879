//
// 
//


@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap');


@font-face {
  font-family: loewMedium;
  src: url("../../../assets/LoewNextArabic/LoewNextArabicMedium.ttf");

}

@font-face {
  font-family: loewMedium;
  src: url("../../../assets/LoewNextArabic/LoewNextArabicMedium.ttf");

}
@font-face {
  font-family: loewBold;
  src: url("../../../assets/LoewNextArabic/LoewNextArabicBold.ttf");

}
@font-face {
  font-family: loewExtraBold;
  src: url("../../../assets/LoewNextArabic/LoewNextArabicExtraBold.ttf");

}
@font-face {
  font-family: loewThin;
  src: url("../../../assets/LoewNextArabic/LoewNextArabicThin.ttf");

}
@font-face {
  font-family: loewHeavy;
  src: url("../../../assets/LoewNextArabic/LoewNextArabicHeavy.ttf");

}