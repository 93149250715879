//
// avatar.scss (avatar thumbnail sizes)
//
.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-semibold;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.avatar-group {
  padding-left: 12px;
  .avatar-group-item {
    margin: 0 0 10px -12px;
    display: inline-block;
  }
}


.avatar-border {
  box-shadow: $box-shadow !important;
  border: 2px solid $white !important;
}