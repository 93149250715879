//
// Daterange
//

.daterangepicker {
    font-family: $font-family-base;
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: $box-shadow;
    background-color: $dropdown-bg;

    .calendar-table {
        border: 1px solid $dropdown-bg;
        background-color: $dropdown-bg;

        td,th {
            color: $gray-600;
        }

        .next,
        .prev {
            span {
                border-color: $gray-500;
            }
        }
    }   

    td {
        &.in-range {
            background-color: lighten($gray-300,5%);
            color: $gray-700;
        }
        &.off,
        &.off.end-date, 
        &.off.in-range,
        &.off.start-date {
            background-color: $dropdown-bg;
            color: $dropdown-link-color;
            opacity: 0.5;
        }
    
        &.active,&.active:hover{
            background-color: $primary;
            color: $white;
        }
    }

    td.available:hover, 
    th.available:hover {
        background-color: $primary;
        color: $white;
    } 

    &:after {
        border-bottom: 6px solid $dropdown-bg;
    }
    &:before {
        border-bottom: 7px solid $dropdown-border-color;
    }

    .ranges {
        li {
            &.active {
                background-color: $primary;
            }
            &:hover {
                background-color: $dropdown-link-hover-bg;
            }
        }
    }

    .drp-buttons {
        border-top: 1px solid $dropdown-border-color;
    }

    select {
        &.ampmselect, 
        &.hourselect, 
        &.minuteselect, 
        &.secondselect {
            background: lighten($dropdown-bg,5%);;
            border: 1px solid lighten($dropdown-bg,5%);
            color: $white;
        }
    }

    &.show-ranges {
        .drp-calendar.left {
            border-left: 1px solid $dropdown-border-color;
        }
    }
}